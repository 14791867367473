import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import TrackingLink from '@components/trackingLink';
import Image from '@components/imgComp';

import ArrowIcon from '@assets/arrow.svg';

import * as styles from './styles.module.scss';

const HeroPost: FC<any> = ({ isAmp = false, content }) => {
    const { t } = useTranslation();
    return (
        <div className={styles['image_wrapper']}>
            {isAmp ? (
                <div
                    className={styles['background_img']}
                    style={{ backgroundImage: `url(${content?.hero[0]?.data?.hero_img?.url})` }}
                />
            ) : (
                <Image
                    {...content?.hero[0]?.data?.hero_img}
                    className={styles['hero_img']}
                    style={{ objectFit: 'cover' }}
                />
            )}
            <div className={styles['hero_post']}>
                <div className={styles['content']}>
                    <h1>{content?.hero[0]?.data?.title?.text}</h1>
                    <TrackingLink
                        href={`/${content?.hero[0]?.uid}/${isAmp ? 'amp/' : ''}`}
                        tracking={{
                            event: 'Hero Article Clicked',
                            obj: {
                                article_name: content?.hero[0]?.data?.title?.text,
                                article_category: content?.hero[0]?.data?.category,
                                uid: content?.hero[0]?.uid,
                            },
                        }}
                    >
                        {t('links.read_post')}
                        <ArrowIcon />
                    </TrackingLink>
                </div>
            </div>
        </div>
    );
};

export default HeroPost;
