import React from 'react';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';

import CategoryTag from '@components/tags/category';
import TrackingLink from '@components/trackingLink';
import Image from '@components/imgComp';

import formatDate from '@utils/formatDate';

import * as styles from './styles.module.scss';

type Props = {
    isAmp?: boolean;
    hero_img: {
        url: string;
        alt: string;
    };
    title: {
        text: string;
    };
    uid: string;
    category: string;
    date: string;
    body: {
        slice_type: string;
        primary: {
            text: {
                text: string;
            };
        };
    }[];
    className: string;
    tracking: any;
    loading: 'lazy' | 'eager';
};

const PostCard: React.FunctionComponent<Props> = ({
    isAmp = false,
    hero_img,
    title,
    uid,
    category,
    date,
    body,
    className,
    tracking,
    loading,
}) => {
    const { t } = useTranslation();
    return (
        <div className={classnames(styles['post_card'], className)}>
            <TrackingLink href={`/${uid}/${isAmp ? 'amp/' : ''}`} tracking={tracking}>
                <div>
                    {isAmp ? (
                        <amp-img
                            src={hero_img?.url}
                            alt={hero_img?.alt}
                            width="280"
                            height="192"
                            layout="responsive"
                            style={{ objectFit: 'cover', maxHeight: 230 }}
                        />
                    ) : (
                        <Image
                            {...hero_img}
                            opts={{ loading }}
                            style={{ objectFit: 'cover' }}
                            className={styles['img_wrapper']}
                        />
                    )}
                    <span className={styles['post_title']}>{title?.text}</span>
                    <div className={styles['post_info']}>
                        <CategoryTag category={category} />
                        <span>{formatDate(date)}</span>
                    </div>
                    <p>
                        {body.filter(({ slice_type }) => slice_type === 'content')[0]?.primary?.text?.text.slice(0, 43)}
                        <span>{t('category_page.read_more')}</span>
                    </p>
                </div>
            </TrackingLink>
        </div>
    );
};

export default PostCard;
