// extracted by mini-css-extract-plugin
export var brandColor = "#846d3d";
export var mainColor = "#2f4758";
export var subColor = "#c0d1dd";
export var lightColor = "#f4f6f8";
export var greyColor = "#707070";
export var darkColor = "#363636";
export var subscriptionColor = "#65c79b";
export var orderColor = "#298df3";
export var errorColor = "#fd3894";
export var frontlineColor = "#e08912";
export var categoryFoodColor = "#e08912";
export var categoryDogHealthColor = "#298df3";
export var categoryTrainingColor = "#65c79b";
export var categoryLifestyleColor = "#fd3188";
export var category_wrapper = "styles-module--category_wrapper--3SZED";
export var category_header = "styles-module--category_header--13wqq";
export var post_card_grid = "styles-module--post_card_grid--32vCb";
export var post_banner_order_a = "styles-module--post_banner_order_a--16m1z";
export var post_banner_grid = "styles-module--post_banner_grid--1sQ7K";
export var post_banner = "styles-module--post_banner--MgWRT";
export var page_navigation = "styles-module--page_navigation--213YR";
export var next = "styles-module--next--2_Xs2";
export var post_card = "styles-module--post_card--2xTET";
export var recommended = "styles-module--recommended--3MQIt";
export var recommended__cards = "styles-module--recommended__cards--2w6pt";