// extracted by mini-css-extract-plugin
export var brandColor = "#846d3d";
export var mainColor = "#2f4758";
export var subColor = "#c0d1dd";
export var lightColor = "#f4f6f8";
export var greyColor = "#707070";
export var darkColor = "#363636";
export var subscriptionColor = "#65c79b";
export var orderColor = "#298df3";
export var errorColor = "#fd3894";
export var frontlineColor = "#e08912";
export var categoryFoodColor = "#e08912";
export var categoryDogHealthColor = "#298df3";
export var categoryTrainingColor = "#65c79b";
export var categoryLifestyleColor = "#fd3188";
export var post_card = "styles-module--post_card--1ScRP";
export var post_title = "styles-module--post_title--TVVs4";
export var post_info = "styles-module--post_info--1jHMw";
export var img_wrapper = "styles-module--img_wrapper--3tSpP";