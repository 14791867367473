import React, { useEffect, FC } from 'react';
import { useTranslation } from 'react-i18next';
import { trackCustomEvent } from 'gatsby-plugin-google-analytics';

import HeroPost from '@components/heroPost';
import NewPosts from '@components/newPosts';
import PostCard from '@components/cards/post';
import SEO from '@components/seo';

import * as styles from './styles.module.scss';
import FixedCta from '../components/fixedCta';

const Home: FC = ({ location, pageContext }) => {
    const { t } = useTranslation();
    // if (!data) return null;

    useEffect(() => {
        // if (window) window.analytics.track('Homepage Viewed');
        trackCustomEvent({ category: 'Homepage Viewed', action: 'Pageview' });
    }, []);

    return (
        <>
            <SEO {...location} />
            <FixedCta />
            <div>
                <HeroPost {...pageContext} />

                {/* recommended posts */}
                <div className={styles['recommended']}>
                    <h1>{t('side_bars.recommended')}</h1>
                    <div className={styles['recommended__cards']}>
                        {pageContext?.content?.recommended.map(({ data, uid }) => (
                            <PostCard
                                tracking={{
                                    event: 'Recommended Article Clicked',
                                    obj: {
                                        article_category: data?.category,
                                        article_name: data?.title.text,
                                        uid,
                                    },
                                }}
                                isAmp={pageContext?.isAmp}
                                loading="lazy"
                                {...data}
                                uid={uid}
                                key={uid}
                            />
                        ))}
                    </div>
                </div>
                <NewPosts {...pageContext} />
            </div>
        </>
    );
};

export default Home;
